import { defineStore } from 'pinia'
import type { Cat } from '~/types/cat'
import type { Breed } from '~/types/breed'
import type { Brand } from '~/types/brand'
import type { SicknessQuestion } from '~/types/sickness-question'
import type { Profile } from '~/types/profile'
import { v4 as uuidv4 } from 'uuid';

const useFunnelStore = defineStore({
  id: 'funnel',
  persist: {
    storage: persistedState.localStorage,
    paths: ['profile', 'cats', 'countCats', 'step', 'currentCatIndex', 'initialized']
  },
  state: () => ({
    nextStepButtonDisabled: true,
    displaySteps: false,
    step: null as string | null,
    lastStep: null as string | null,
    countCats: null as number | null,
    currentCatIndex: null as number | null,
    cats: [] as Cat[],
    profile: {
      email: null,
      firstName: null,
      phone: null,
      optin: true,
      mergeMenu: false
    } as Profile,
    nextStep: null as string | null,
    previousStep: null as string | null,
    breeds: [] as Array<Breed>,
    sicknessQuestions: [] as Array<SicknessQuestion>,
    initialized: false,
    promo: null as any | null,
    promoModalOpened: false,
  }),
  actions: {
    setCountCats(count: number) {
      this.countCats = count
      if (this.cats.length === 0) {
        this.addCat()
      }
    },
    addCat() {
      this.cats.push({
        uuid: uuidv4(),
        name: null,
        goldenRules: null,
        gender: null,
        age: {
          value: 3,
          units: 'years'
        },
        dob: {
          day: null,
          month: null,
          year: null
        },
        ageType: 'age',
        breed: null,
        nurtered: {
          value: null,
          planned: null,
          plannedDate: null
        },
        weight: {
          value: null,
          estimation: true,
          units: 'kg'
        },
        activity: null,
        fatness: null,
        allergies: {
          value: null,
          ingredients: {
            meat: [],
            cereals: []
          }
        },
        dry: {
          value: null,
          brand: null,
          preference: null
        },
        wet: {
          value: null,
          brand: null,
          preference: null
        },
        preferences: null,
        hasSicknessIssues: null,
        sicknesses: {}
      })
    },
    setStep(step: string) {
      this.step = step
    },
    setDisplaySteps(display: boolean) {
      this.displaySteps = display
    },
    setCurrentCatIndex(index: number) {
      this.currentCatIndex = index
    },
    reset() {
      this.step = null
      this.countCats = null
      this.currentCatIndex = null
      this.cats = []
      this.profile = {
        email: null,
        firstName: null,
        phone: null,
        mergeMenu: false,
        optin: false
      }
    },
    setNextStepButtonDisabled(disabled: boolean) {
      this.nextStepButtonDisabled = disabled
    },
    setNextStep(step: string) {
      this.nextStep = step
    },
    setPreviousStep(step: string) {
      this.previousStep = step
    },
    async fetchBreeds() {
      if (this.breeds.length === 0) {
        const data = await $fetch('/api/breeds')
        this.breeds = data.map((breed) => {
          return {
            value: breed.value,
            label: breed.label,
            maleWeight: breed.male_weight,
            femaleWeight: breed.female_weight,
            suggested: breed.suggested
          }
        })
      }
    },
    async fetchSicknessQuestions() {
      if (this.sicknessQuestions.length > 0) {
        return
      }
      this.sicknessQuestions = await $fetch('/api/sickness-questions')
    },
    async initData(token: string) {
      try {
        const response = await $fetch(`/api/funnel/data/init/${token}`, {
          baseURL: useRuntimeConfig().public.appHost,
          method: 'POST'
        })

        if (response.form.funnel_data) {
          this.countCats = response.form.funnel_data.countCats
          this.profile = response.form.funnel_data.profile
          this.cats = response.form.funnel_data.cats
          this.lastStep = response.form.funnel_data.step
          this.currentCatIndex = response.form.funnel_data.currentCatIndex
        } else {
          this.reset()
        }

        this.initialized = true
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async saveData() {
      const data = {
        countCats: this.countCats,
        profile: this.profile,
        cats: this.cats,
        step: this.step,
        currentCatIndex: this.currentCatIndex
      }

      await $fetch(`/api/funnel/data/save`, {
        method: 'POST',
        baseURL: useRuntimeConfig().public.appHost,
        body: {
          data
        }
      })
    },
    async processData() {
      await $fetch(`/api/funnel/data/process`, {
        method: 'POST',
        baseURL: useRuntimeConfig().public.appHost,
      })
    },
    async applyCoupon({ code, type }: { code: string, type: 'sponsor' | 'coupon' }) {
      try {
        const response = await $fetch(`/api/funnel/form/apply-coupon/${code}/${type}`, {
          method: 'POST',
          baseURL: useRuntimeConfig().public.appHost,
        })
        this.promo = {
          ...response.data,
          type
        }
        this.promoModalOpened = true
      } catch (error) {
        console.error(error)
        this.promoModalOpened = false
      }
    },
    async fetchKibbleBrands(search: string): Promise<Brand[]> {
      const data = await $fetch('/api/kibble', {
        params: {
          q: search
        },
        baseURL: useRuntimeConfig().public.appHost
      })
      // Transform data to Brand type
      return data.map((brand: {
        id: number,
        brand_name: string,
        product_name: string
      }) => {
        return {
          id: brand.id,
          name: `${brand.brand_name} - ${brand.product_name}`
        }
      })
    },
    async fetchPateBrands(search: string): Promise<Brand[]> {
      const data = await $fetch('/api/pate', {
        params: {
          q: search
        },
        baseURL: useRuntimeConfig().public.appHost
      })
      // Transform data to Brand type
      return data.map((brand: {
        id: number,
        brand_name: string,
        product_name: string
      }) => {
        return {
          id: brand.id,
          name: `${brand.brand_name} - ${brand.product_name}`
        }
      })
    }
  },
  getters: {
    currentCat(): Cat | null {
      if (this.currentCatIndex === null) {
        return null
      }

      return this.cats[this.currentCatIndex]
    },
    currentCatAllergiesModalOpened(): boolean {
      if (!this.currentCat) {
        return false
      }

      return this.currentCat.allergies.ingredients.meat.includes(1)
    }
  },
})

export default useFunnelStore